import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import { setBillingEntities, setError } from '../../redux/actions';
import {
    CREATE_BILLING_ENTITY,
    GET_BILLING_ENTITIES,
    UPDATE_BILLING_ENTITY,
} from '../../redux/reducers/constants';

function* getBillingEntities(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingEntity,
            operation.id,
            action.entity
        );
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingEntity,
            operation.id,
            action.entity
        );
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

export function* getBillingEntitiesSaga() {
    yield takeLatest(GET_BILLING_ENTITIES, getBillingEntities);
}

export function* createBillingEntitySaga() {
    yield takeLatest(CREATE_BILLING_ENTITY, createBillingEntity);
}

export function* updateBillingEntitySaga() {
    yield takeLatest(UPDATE_BILLING_ENTITY, updateBillingEntity);
}

const billingSagas = [
    createBillingEntitySaga,
    getBillingEntitiesSaga,
    updateBillingEntitySaga,
];

export default billingSagas;
