import client from '../../libs/client';

export default class BillingNewGateway {
    getBillingUrlBase = (operationId) => {
        return `/operations/${operationId}/billing`;
    };

    getBillingEntities = async (operationId) => {
        const { data: billingEntities } = await client.get(
            `${this.getBillingUrlBase(operationId)}/billing-entities`
        );
        return billingEntities;
    };

    createBillingEntity = async (operationId, billingEntity) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/billing-entities`,
            billingEntity
        );
        return res;
    };

    updateBillingEntity = async (operationId, billingEntity) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/billing-entities/${
                billingEntity.id
            }`,
            billingEntity
        );
        return res;
    };
}
