import { SET_BILLABLE_ENTITIES, SET_BILLING_ENTITIES } from './constants';

const initialState = {
    participants: [],
    excess: [],
    downloadLoading: false,
};

function billingNewReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BILLING_ENTITIES: {
            return { ...state, billingEntities: [...action.billingEntities] };
        }
        case SET_BILLABLE_ENTITIES: {
            return { ...state, billableEntities: [...action.billableEntities] };
        }
        default:
            return state;
    }
}

export default billingNewReducer;
